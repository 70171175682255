import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { Link } from "gatsby"
import gcg from "../images/gcg.png"
import tribeca from "../images/tribeca.png"
import sitting from "../images/sitting.png"
import billing from "../images/billing.png"
import { useEffect } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';


// markup
const Sketch = () => {
  useEffect(() => {
    // 👇️ adding multiple classes to body tag
    document.body.classList.add(
      'd-flex',
      'h-100',
      'text-center',
      'text-white',
      'bg-dark'
    );
  }, []);
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <GatsbySeo
		      title='Game Change Game Movie'
		      description='Game Change Game is a four part series that takes viewers inside a basketball season like no other; one that found the players navigating circumstances of unforeseen sacrifice, loss and their own morality. As a social justice reckoning played out across the globe, sitting on the bench was not an option. We see the drama unfold in real time, as the players union contemplates their response to wave after wave of police brutality. We focus on the executive leadership and key players, guiding them towards a singular voice and collective action. We know this moment will change basketball forever, but will it create lasting change outside of the arena?'
		    />
      <header className="mb-auto">
        <div>
          <nav className="nav nav-masthead justify-content-center ">
          <Link to="/" className="nav-link fw-bold py-1 px-0 ">Home</Link>
            <Link to="/synopsis" className="nav-link fw-bold py-1 px-0 ">Synopsis</Link>
            <Link to="/trailer" className="nav-link fw-bold py-1 px-0 ">Trailer</Link>
            <Link to="/contact" className="nav-link fw-bold py-1 px-0 ">Contact</Link>
          </nav>
        </div>
      </header>

      <main class="px-3">
        <img src={gcg} alt="Game Change Game" className="img-fluid titlecard" />
      </main>

      <footer className="mt-auto">
        <a href="https://tribecafilm.com/films/game-change-game-2022" className="nav-link fw-bold py-1 px-0 " target="_blank" rel="noreferrer"><img src={tribeca} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid tribeca" /></a>
        <img src={sitting} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid sitting" />
        <br />
        <img src={billing} alt="Tribeca Film Festival 2022 Official Selection" className="img-fluid billing" />
      </footer>

    </div>
  )
}

export default Sketch
